/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { RouterProps, showNotification } from '../App';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import SettingsIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import { RvLoader } from '../components/Loader';
import { client } from '..';
// tslint:disable-next-line: max-line-length
import { ApplicationSettingsData, ClientNotesSettings, DataPublisherSettings, DocumentPublisherSettings, FutureTimeSynchronization, LabelSettings, Licencing, MobileSettings, retrieveApplicationSettingsData, SettingName, StaffBudgetSettings, SystemOptions, TaskPublisherSettings } from './ApplicationSettingsRepository';
import { FirmSettingConfigurationBanner } from '../firmSettingConfiguration/FirmSettingConfigurationBanner';
import { SettingNotificationSubscription } from '../firmSettingConfiguration/FirmSettingConfigurationRepository';
import DocumentPublishingTab from './tabs/DocumentPublishingTab';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
// import RVMobileTab from './tabs/RVMobileTab';
import DataPublishingTab from './tabs/DataPublishingTab';
import LicencingTab from './tabs/LicencingTab';
import StaffBudgetsTab from './tabs/StaffBudgetsTab';
import ReorderIcon from '@material-ui/icons/Reorder';
import FutureTimeSynchronizationTab from './tabs/FutureTimeSynchronizationTab';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import TaskPublishingTab from './tabs/TaskPublishingTab';
import ClientNotesTab from './tabs/ClientNotesTab';
import LabelSettingsTab from './tabs/LabelSettingsTab';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SystemOptionsTab from './tabs/SystemOptionsTab';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        backgroundColor: '#fff',
        margin: '0 -15px',
        padding: '15px',
    },
    sidebar: {
        padding: '0 15px',
        maxWidth: '400px',
        width: '100%',
        position: 'relative',
        flex: 1,
        display: 'flex',
    },
    sidebarInner: {
        position: 'relative',
        flex: 1,
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        // overflow: 'auto',
    },
    sidebarList: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: 'auto',
        paddingRight: '10px',
        '& .Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        '& .Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    },
    content: {
        padding: '0 15px',
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        '& > div': {
            flexGrow: 1,
            maxWidth: '700px',
        }
    },
    tabLoaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    }
});

export enum ApplicationSettingsList {
    DataPublishing = 0,
    DocumentPublishing = 'DocumentPublishing',
    Licencing = 'Licencing',
    // RVMobile = 'RVMobile',
    StaffBudgets = 'StaffBudgets',
    FutureTimeSynchronization = 'FutureTimeSynchronization',
    TaskPublishing = 'TaskPublishing',
    ClientNotes = 'ClientNotes',
    LabelSettings = 'LabelSettings',
    SettingOptions = 'SystemOptions'
}

interface ApplicationSettingsPageState {
    selectedTable: ApplicationSettingsList;
    tabs: SettingName[];
    isTabLoading: boolean;
    mobileSettings:            MobileSettings | null;
    dataPublisherSettings:     DataPublisherSettings | null;
    documentPublisherSettings: DocumentPublisherSettings | null;
    taskPublisherSettings: TaskPublisherSettings | null;
    licencing: Licencing | null;
    staffBudgetSettings: StaffBudgetSettings | null;
    futureTimeSynchronization: FutureTimeSynchronization | null;
    clientNotesSettings: ClientNotesSettings | null;
    labelSettings: LabelSettings | null;
    systemOptions: SystemOptions | null;
}

interface ApplicationSettingsPageProps extends RouterProps {

}

export default function ApplicationSettingsPage(props: ApplicationSettingsPageProps) {

    const classes = useStyles();

    const [pageState, setPageState] = useState<ApplicationSettingsPageState>({
        selectedTable: 0,
        tabs: [],
        isTabLoading: true,
        mobileSettings:            null,
        dataPublisherSettings:     null,
        documentPublisherSettings: null,
        taskPublisherSettings:     null,
        licencing:                 null,
        staffBudgetSettings:       null,
        futureTimeSynchronization: null,
        clientNotesSettings:       null,
        labelSettings:             null,
        systemOptions:             null
    });

    // tslint:disable-next-line: no-any
    let routeCommand = props.match.params.command;

    useEffect(() => {

        fetchTabData();

        var routeTab: ApplicationSettingsList = props.match.params.tab as ApplicationSettingsList ?? ApplicationSettingsList.ClientNotes;

        // tslint:disable-next-line: no-console
        console.log('routeCommand', routeCommand);

        setPageState({
            ...pageState,
            selectedTable: routeTab ? routeTab : pageState.selectedTable
        });

    // tslint:disable-next-line: align
    }, []);

    useEffect(() => {   
        const subscribeSettingNotificationObj = subscribeSettingNotification();
        // Cleanup
        return () => {
            subscribeSettingNotificationObj.unsubscribe();
        };

    // tslint:disable-next-line: align
    }, []);

    const subscribeSettingNotification = () => {
        return (
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: SettingNotificationSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.settingNotification.status) {
                            showNotification('Success', results.data.settingNotification.message, 'info');

                        } else {
                            showNotification('Failed', results.data.settingNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const fetchTabData = () => {
        retrieveApplicationSettingsData(
            true,
            // tslint:disable-next-line: no-console
            (data: ApplicationSettingsData) => onApplicationSettingsRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onApplicationSettingsRetrieved = (data: ApplicationSettingsData) => {
        setPageState((prevState) => {
            return {
                ...prevState,
                tabs: data.settings.applications.settingNames.settingName,
                mobileSettings:    data.settings.applications.mobileSettings,
                dataPublisherSettings:     data.settings.applications.dataPublisherSettings,
                documentPublisherSettings: data.settings.applications.documentPublisherSettings,
                taskPublisherSettings: data.settings.applications.taskPublisherSettings,
                licencing: data.settings.applications.licencing,
                staffBudgetSettings: data.settings.applications.staffBudgetSettings,
                futureTimeSynchronization: data.settings.applications.futureTimeSynchronization,
                clientNotesSettings: data.settings.applications.clientNotesSettings,
                labelSettings: data.settings.applications.labelSettings,
                systemOptions: data.settings.applications.systemOptions,
                isTabLoading: false
            };
        });
    };

    const getIcon = (internalName: string) => {

        // tslint:disable-next-line: max-line-length
        if (internalName === ApplicationSettingsList[ApplicationSettingsList.DocumentPublishing] || internalName === ApplicationSettingsList[ApplicationSettingsList.TaskPublishing]) {
            return (
                <Avatar>
                    <InsertDriveFileIcon />
                </Avatar>
            );
        }

        if (internalName === ApplicationSettingsList[ApplicationSettingsList.Licencing]) {
            return (
                <Avatar>
                    <VpnKeyIcon />
                </Avatar>
            );
        }

        // if (internalName === ApplicationSettingsList[ApplicationSettingsList.RVMobile]) {
        //     return (
        //         <Avatar>
        //             <PhonelinkSetupIcon />
        //         </Avatar>
        //     );
        // }

        if (internalName === ApplicationSettingsList[ApplicationSettingsList.StaffBudgets]) {
            return (
                <Avatar>
                    <ReorderIcon />
                </Avatar>
            );
        }

        if (internalName === ApplicationSettingsList[ApplicationSettingsList.FutureTimeSynchronization]) {
            return (
                <Avatar>
                    <UpdateOutlinedIcon />
                </Avatar>
            );
        }

        if (internalName === ApplicationSettingsList[ApplicationSettingsList.LabelSettings]) {
            return (
                <Avatar>
                    <TextFieldsIcon />
                </Avatar>
            );
        }
                
        return (
            <Avatar>
                <SettingsIcon />
            </Avatar>
        );
    };

    const isHasAccess = (internalName: string): boolean => {
        
        // if (internalName === ApplicationSettingsList[ApplicationSettingsList.EmailConfiguration]) {
        //     return UserFeature.HasAccess(UserFeatureEnum.hasInnographyLink);
        // }

        // if (internalName === ApplicationSettingsList[ApplicationSettingsList.EnquiryDefaultConfiguration]) {
        //     return UserFeature.HasAccess(UserFeatureEnum.hasVisionSix);
        // }

        return true;
    };

    const onClickBack = () => {
        props.history.push('/configurationDashboard');
    };

    const onClickTab = (value: ApplicationSettingsList) => () => {

        setPageState({
            ...pageState,
            selectedTable: value
        });

        props.history.push(`/applicationSettings/${value}`);
    };
    
    return (
        <div className={classes.root}>
            <FirmSettingConfigurationBanner 
                title="Application Settings"
                onClickBack={onClickBack}
            />
            <div className={classes.wrapper}>
                {pageState.isTabLoading ? (
                    <div className={classes.tabLoaderWrapper}>
                        <RvLoader />
                    </div>
                ) : (
                    <>
                        <div className={classes.sidebar}>
                            <div className={classes.sidebarInner}>
                                <List className={classes.sidebarList}>
                                    {pageState.tabs.map((tab: SettingName) => isHasAccess(tab.internalName) && (
                                            <ListItem 
                                                key={tab.guidID}
                                                button={true} 
                                                onClick={onClickTab(tab.internalName as ApplicationSettingsList)}
                                                selected={pageState.selectedTable === tab.internalName}
                                            >
                                                <ListItemAvatar>
                                                    {getIcon(tab.internalName)}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={tab.title}
                                                    secondary={tab.description}
                                                />
                                            </ListItem>
                                        )
                                    )}
                                </List>
                            </div>
                        </div>
                        <div className={classes.content}>
                            {(pageState.selectedTable === ApplicationSettingsList.ClientNotes) && (
                                <ClientNotesTab 
                                    data={pageState.clientNotesSettings}
                                    onSave={(data: ClientNotesSettings) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                clientNotesSettings: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {(pageState.selectedTable === ApplicationSettingsList.DataPublishing) && (
                                <DataPublishingTab 
                                    data={pageState.dataPublisherSettings}
                                    onSave={(data: DataPublisherSettings) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                dataPublisherSettings: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {(pageState.selectedTable === ApplicationSettingsList.DocumentPublishing) && (
                                <DocumentPublishingTab 
                                    data={pageState.documentPublisherSettings}
                                    onSave={(data: DocumentPublisherSettings) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                documentPublisherSettings: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {(pageState.selectedTable === ApplicationSettingsList.TaskPublishing) && (
                                <TaskPublishingTab 
                                    data={pageState.taskPublisherSettings}
                                    onSave={(data: TaskPublisherSettings) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                taskPublisherSettings: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {/* {(pageState.selectedTable === ApplicationSettingsList.RVMobile) && (
                                <RVMobileTab 
                                    data={pageState.mobileSettings}
                                    onSave={(data: MobileSettings) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                mobileSettings: data
                                            };
                                        });
                                    }}
                                />
                            )} */}
                            {(pageState.selectedTable === ApplicationSettingsList.Licencing) && (
                                <LicencingTab 
                                    data={pageState.licencing}
                                    onSave={(data: Licencing) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                licencing: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {(pageState.selectedTable === ApplicationSettingsList.StaffBudgets) && (
                                <StaffBudgetsTab 
                                    data={pageState.staffBudgetSettings}
                                    onSave={(data: StaffBudgetSettings) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                staffBudgetSettings: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {(pageState.selectedTable === ApplicationSettingsList.FutureTimeSynchronization) && (
                                <FutureTimeSynchronizationTab 
                                    data={pageState.futureTimeSynchronization}
                                    onSave={(data: FutureTimeSynchronization) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                futureTimeSynchronization: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {(pageState.selectedTable === ApplicationSettingsList.LabelSettings) && (
                                <LabelSettingsTab 
                                    data={pageState.labelSettings}
                                    onSave={(data: LabelSettings) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                labelSettings: data
                                            };
                                        });
                                    }}
                                />
                            )}
                            {(pageState.selectedTable === ApplicationSettingsList.SettingOptions) && (
                                <SystemOptionsTab 
                                    data={pageState.systemOptions}
                                    onSave={(data: SystemOptions) => {
                                        setPageState((prevState) => {
                                            return {
                                                ...prevState,
                                                systemOptions: data
                                            };
                                        });
                                    }}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}