import { FetchPolicy, WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';
import { useQuery } from 'react-apollo';

export interface ApplicationSettingsData {
    settings: Settings;
}
export interface Settings {
    id:           number;
    applications: Applications;
}

export interface Applications {
    id:                        number;
    settingNames:              SettingNames;
    mobileSettings:            MobileSettings;
    dataPublisherSettings:     DataPublisherSettings;
    documentPublisherSettings: DocumentPublisherSettings;
    taskPublisherSettings:     TaskPublisherSettings;
    licencing:                 Licencing;
    staffBudgetSettings:       StaffBudgetSettings;
    futureTimeSynchronization: FutureTimeSynchronization;
    clientNotesSettings:       ClientNotesSettings;
    labelSettings:             LabelSettings;
    systemOptions:             SystemOptions;
}

export interface SystemOptions {
    config: SystemOptionsConfig;
}

export interface LabelSettings {
    config: LabelSettingsConfig;
}

export interface ClientNotesSettings {
    config: ClientNotesSettingsConfig;
}

export interface ClientNotesSettingsConfig {
    enableClientNotesSync: boolean;
}

export interface StaffBudgetSettings {
    config: StaffBudgetSettingsConfig;
}

export interface StaffBudgetSettingsConfig {
    enableBudgetSync: boolean;
}

export interface DataPublisherSettings {
    config: DataPublisherSettingsConfig;
}

export interface DataPublisherSettingsConfig {
    publishTimeline:   boolean;
    publishFinancials: boolean;
    timeOnly:          boolean;
}

export interface DocumentPublisherSettings {
    config: DocumentPublisherSettingsConfig;
}

export interface TaskPublisherSettings {
    config: TaskPublisherSettingsConfig;

}

export interface FutureTimeSynchronization {
    enableFutureTimeSynchronization: boolean;
}

export interface DocumentPublisherSettingsConfig {
    publishDocuments: boolean;
    publishDocumentFolders: boolean;
}

export interface TaskPublisherSettingsConfig {
    publishTasks: boolean;
}

export interface MobileSettings {
    config: MobileSettingsConfig;
}

export interface MobileSettingsConfig {
    showTimerButton:     boolean;
    showMatterSelection: boolean;
    showPhoneCallButton: boolean;
}

export interface Licencing {
    config: LicencingConfig;
}

export interface LicencingConfig {
    internalRegisteredUsers: number;
}
export interface SettingNames {
    recordCount: number;
    settingName: SettingName[];
}

export interface SettingName {
    id:           number;
    title:        string;
    description:  string;
    guidID:       string;
    internalName: string;
}

export interface LabelSettingsConfig {
    personActingLabel: string | null;
    personResponsibleLabel: string | null;
}

export interface SystemOptionsConfig {
    emailSizeLimit: number | null;
}

export function retrieveApplicationSettingsData(
    refreshData: boolean,
    onSuccess: (data: ApplicationSettingsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: APPLICATION_SETTINGS_QUERY,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

export const APPLICATION_SETTINGS_QUERY = gql`
    query applicationSettings {
        settings {
            id
            applications {
                id
                settingNames {
                    recordCount,
                    settingName {
                        id,
                        title,
                        description,
                        guidID,
                        internalName
                    }        
                }
                mobileSettings {
                    config {
                        showTimerButton
                        showMatterSelection
                        showPhoneCallButton
                    }
                }
                dataPublisherSettings {
                    config {
                        publishTimeline
                        publishFinancials
                        timeOnly
                    }
                }
                documentPublisherSettings {
                    config {
                        publishDocuments
                        publishDocumentFolders
                    }
                }
                taskPublisherSettings {
                    config {
                        publishTasks
                    }
                }
                licencing {
                    config {
                        internalRegisteredUsers
                    }
                }
                clientNotesSettings {
                    config {
                        enableClientNotesSync
                    }
                }
                staffBudgetSettings {
                    config {
                        enableBudgetSync
                    }
                }
                futureTimeSynchronization {
                    enableFutureTimeSynchronization
                }
                labelSettings {
                    config {
                        personActingLabel
                        personResponsibleLabel
                    }
                }
                systemOptions {
                    config {
                        emailSizeLimit
                    }
                }
            }
        }
    }
`;

export const useApplicationSettings = (fetchPolicy?: WatchQueryFetchPolicy) => {

    const { data, error, loading } = useQuery<ApplicationSettingsData>(APPLICATION_SETTINGS_QUERY, {
        fetchPolicy: fetchPolicy ?? 'cache-and-network'
    });

    return {
        data,
        error,
        loading
    };

};