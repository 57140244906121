/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { LabelSettings } from '../ApplicationSettingsRepository';
import { LocalStorageEnum } from '../../enums/LocalStorageEnum';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        }
    })
);

interface FormState {
    personActingLabel: string | null | undefined;
    personResponsibleLabel: string | null | undefined;
}
interface LabelSettingsTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

interface LabelSettingsTabProps {
    data: LabelSettings | null;
    onSave?: (data: LabelSettings | null) => void;
}

export default function LabelSettingsTab(props: LabelSettingsTabProps) {

    const classes = useStyles();

    const [state, setState] = useState<LabelSettingsTabState>({
        isLoading: false,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState] = useState<FormState>({
        personActingLabel: props.data?.config.personActingLabel,
        personResponsibleLabel: props.data?.config.personResponsibleLabel,
    });

    // tslint:disable-next-line: no-any
    const [updateLabelSettings] = useMutation<any, LabelSettingsMutationParams>(LabelSettingsMutation);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {
        // console.log('submit');
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        updateLabelSettings({
            variables: {
                personActingLabel: values.personActingLabel, 
                personResponsibleLabel: values.personResponsibleLabel
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');     

                form.setConfig('keepDirtyOnReinitialize', false);

                localStorage.setItem(LocalStorageEnum.PersonResponsible, values.personResponsibleLabel);
                localStorage.setItem(LocalStorageEnum.PersonActing, values.personActingLabel);
                
                const tempInitial: FormState = {
                    personActingLabel: values.personActingLabel,
                    personResponsibleLabel: values.personResponsibleLabel
                };

                form.batch(() => {
                    form.reset(tempInitial);
                });
                
                form.setConfig('keepDirtyOnReinitialize', true);

                setState((_prevState) => {
                    return {
                        ..._prevState,
                        onSubmitLoading: false,
                    };
                });

                if (props.onSave && props.data) {
                    const tempData = props.data;
                    tempData.config.personActingLabel = values.personActingLabel;
                    tempData.config.personResponsibleLabel = values.personResponsibleLabel;
                    props.onSave(tempData);
                } 
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update application security settings', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit} id="securitytab">
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="personActingLabel"
                                            subscription={{touched: true, error: true, value: true}}
                                            // validate={form.getFieldState('passwordLength')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Person Acting Label"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    required={form.getFieldState('personActingLabel')?.value !== null ? true : false}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Person Acting label is required.'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="personResponsibleLabel"
                                            subscription={{touched: true, error: true, value: true}}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Person Responsible Label"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    required={form.getFieldState('personResponsibleLabel')?.value !== null ? true : false}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Person Responsible label is required.'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

interface LabelSettingsMutationParams {
    personActingLabel: string | null;
    personResponsibleLabel: string | null;
}

const LabelSettingsMutation = gql`
    mutation UpdateLabelSettings ($personActingLabel: String, $personResponsibleLabel: String){
        applicationSettings {
            labelSettings {
                update (personActingLabel: $personActingLabel, personResponsibleLabel: $personResponsibleLabel) {
                    error
                    status
                }
            }
        }
    }
`;