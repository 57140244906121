//  0 - External message to a client (External)
// 1 - Internal message only from an internal user (Internal)
// 2 - It is an external message from a client (External)
export const SourceMessage = {
    ExternalMessageToClient: 0,
    InternalMessageOnlyFromInternalUser: 1,
    ExternalMessageFromClient: 2,
} as const;

export const DocumentListFile = {
    ServiceLevel: 'https://redraincorp.sharepoint.com/:b:/s/RedViewProduct/EfxVw0U8GLJLvVMCK5JfaR8BLHvxV-BOesbjDCcI1Anz5g?e=XiICXx'
} as const;