/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, Switch, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { AnyObject, Field, FieldInputProps, Form, FormSpy } from 'react-final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { DocumentPublisherSettings } from '../ApplicationSettingsRepository';
import { useTenant } from '../../hooks/useTenant';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        }
    })
);

interface FormState {
    publishDocuments: boolean;
    publishDocumentFolders: boolean;
}
interface DocumentPublishingTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

interface DocumentPublishingTabProps {
    data: DocumentPublisherSettings | null;
    onSave?: (data: DocumentPublisherSettings | null) => void;
}

export default function DocumentPublishingTab(props: DocumentPublishingTabProps) {

    const classes = useStyles();

    const tenant = useTenant();

    const [state, setState] = useState<DocumentPublishingTabState>({
        isLoading: false,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState] = useState<FormState>({
        publishDocuments: props.data && props.data.config ? props.data.config.publishDocuments : false,
        publishDocumentFolders: props.data && props.data.config ? props.data.config.publishDocumentFolders : false,
    });

    // tslint:disable-next-line: no-any
    const [updateDocumentPublishingMutation] = useMutation<any, DocumentPublishingMutationParams>(DOCUMENT_PUBLISHING_MUTATION);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {

        // tslint:disable-next-line: no-console
        updateDocumentPublishingMutation({
            variables: {
                publishDocuments: values.publishDocuments,
                publishDocumentFolders: values.publishDocumentFolders
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info'); 
                
                if (props.onSave && props.data) {
                    const tempData = props.data;
                    tempData.config.publishDocuments = values.publishDocuments;
                    tempData.config.publishDocumentFolders = values.publishDocumentFolders;
                    props.onSave(tempData);
                }
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update document publisher.', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    const onChangge = (input: FieldInputProps<FormState, HTMLElement>, form: FormApi<FormState>) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        
        input.onChange(checked);

        if (!checked) { form.change('publishDocumentFolders', false); }
    };

    if (state.isLoading || tenant.loading) {
        return (
            <div className={classes.root}>
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            </div>
        );
    } 

    const sourceSystem = tenant.data?.tenant.sourceSystem === 'PracticeEvolve';

    return (
        <div className={classes.root}>
            <Form
                onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                initialValues={formState}
                initialValuesEqual={() => true}
                keepDirtyOnReinitialize={true}
                subscription={{
                    submitting: true,
                    pristine: true
                }}
                render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values
                }) => (
                    <form onSubmit={handleSubmit} id="DocumentPublishingTab">
                        <FormSpy 
                            subscription={{ pristine: true }}
                            // tslint:disable-next-line: no-shadowed-variable
                            onChange={props => {
                                onFormValueChanged(props);
                            }}
                        />
                        
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} md={12} justify="flex-start">
                                <FormGroup
                                    className={classes.formGroup}
                                >
                                    <Field
                                        name="publishDocuments"
                                        subscription={{touched: true, error: true, value: true}}
                                    >                                            
                                        {({ input, meta }) => (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        {...input}
                                                        checked={input.value}
                                                        onChange={onChangge(input, form)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Publish Documents"
                                            />
                                        )}
                                    </Field>
                                    {sourceSystem ? (
                                        <FormSpy subscription={{ values: true }}>
                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                            {({ values }) => (
                                                <Field
                                                    name="publishDocumentFolders"
                                                    subscription={{touched: true, error: true, value: true}}
                                                >                                            
                                                    {({ input, meta }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    {...input}
                                                                    checked={input.value}
                                                                    onChange={input.onChange}
                                                                    color="primary"
                                                                    disabled={!values.publishDocuments}
                                                                />
                                                            }
                                                            label="Publish Document Folders"
                                                        />
                                                    )}
                                                </Field>
                                            )}
                                        </FormSpy>
                                    ) : undefined}
                                </FormGroup>
                            </Grid>
                            <Grid item={true} xs={12} md={12} justify="flex-end">
                                <div className={classes.buttonWrapper}>
                                    <Button 
                                        variant="outlined" 
                                        color="primary"
                                        type="submit"
                                        disabled={submitting || pristine || state.onSubmitLoading}
                                    >
                                        Save
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        color="primary"
                                        type="button"
                                        disabled={submitting || pristine}
                                        onClick={onReset(form)}
                                    >
                                        Reset
                                    </Button>
                                </div>
                            </Grid>
                            {/* {printJson(values)} */}
                    </Grid>
                    </form>
                )}
            />
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

interface DocumentPublishingMutationParams {
    publishDocuments: boolean;
    publishDocumentFolders: boolean;
}

const DOCUMENT_PUBLISHING_MUTATION = gql`
    mutation DocumentPublishingMutation($publishDocuments: Boolean, $publishDocumentFolders: Boolean) {
        applicationSettings {
            documentPublisherSettings {
                update (publishDocuments: $publishDocuments, publishDocumentFolders: $publishDocumentFolders) {
                    error
                    status
                }
            }
        }
    }
`;