/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStyles, FormGroup, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { SystemOptions } from '../ApplicationSettingsRepository';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        }
    })
);

interface FormState {
    emailSizeLimit: number | null;
}
interface SystemOptionsTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

interface SystemOptionsTabProps {
    data: SystemOptions | null;
    onSave?: (data: SystemOptions | null) => void;
}

export default function SystemOptionsTab(props: SystemOptionsTabProps) {

    const classes = useStyles();

    const [state, setState] = useState<SystemOptionsTabState>({
        isLoading: false,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState] = useState<FormState>({
        emailSizeLimit: props.data ? props.data!.config.emailSizeLimit : null,
    });

    // tslint:disable-next-line: no-any
    const [updateSystemOptionConfig] = useMutation<any, UpdateSystemConfigMutationParams>(UpdateSystemOptionConfigMutation);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {

        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true
            };
        });

        // tslint:disable-next-line: no-console
        updateSystemOptionConfig({
            variables: {
                emailSizeLimit: values.emailSizeLimit,
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');  

                const initial: FormState = {
                    emailSizeLimit: values.emailSizeLimit
                };

                form.reset(initial);

                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false
                    };
                });
        
                if (props.onSave && props.data) {
                    const tempData = props.data;
                    tempData.config.emailSizeLimit = values.emailSizeLimit;
                    props.onSave(tempData);
                }   
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update data system options.', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit} id="SystemOptionsTab">
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={4} justify="flex-start">
                                        <FormGroup
                                            className={classes.formGroup}
                                        >
                                            <Field
                                                name="emailSizeLimit"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <TextField
                                                        {...input}
                                                        label="Email Size Limit"
                                                        type="number"
                                                        // required={true}
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        autoComplete="abcd"
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Required'
                                                                : undefined
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface UpdateSystemConfigMutationParams {
    emailSizeLimit: number;
}

const UpdateSystemOptionConfigMutation = gql`
    mutation updateSystemOptionConfig($emailSizeLimit: Int) {
        applicationSettings {
            systemOptions {
                update(emailSizeLimit: $emailSizeLimit) {
                    status
                }
            }
        }
    }
`;